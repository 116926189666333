import React, {useState } from 'react';
import { Typography, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip,
  InputBase,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
  errorIcon,
  removeIcon,
  caledarIcon,
  branch1,
  branch2,
  coldIcon,
  hotIcon
} from "./assets";
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

  export interface Props {
      tenantList:any
      addMeter: (event: any,value:number) => void;
  }

  import CloseRoundedIcon from '@mui/icons-material/Close';

  const itemsPerPage = 10;

  interface TenantDialogProps {
    isEdit: boolean;
  }

  export const CustomerTenants = ({tenantList,addMeter}:Props) => {
    const [addTenantsDialoge , setAddTenantsDialoge ] = useState<TenantDialogProps | null>(null);
    const [deleteButtonDialoge , setDeleteButtonDialoge ] = useState(false);
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(tenantList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const handleNextPage = () => {
      if (currentPage < Math.ceil(tenantList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

    const questionSchema = () => {
      return Yup.object().shape({
        tenantName: Yup.string().required("Tenant Name is required"),
        meters: Yup.array()
          .of(
            Yup.object().shape({
              type: Yup.string(),
              meterNumner: Yup.string(),
              lastReading: Yup.string(),
              lastReadingDate: Yup.string(),
            })
          )
          .notRequired(),
      });
    };

    return (
      <Box sx={webStyle.propertiesContainer}>
      <Box sx={webStyle.propertiesTableHeader}>
        <Box sx={webStyle.searchContainer}>
          <Box sx={webStyle.fieldControl}>
            <input
                style={{...webStyle.inputElemnt, ...webStyle.searchElement}}
              data-test-id="search"
              type={"text"}
              placeholder="Search"
              onChange={(event) => {
                // searchProperties(event.target.value);
              }}
            />
            <img src={searchIcon} alt="" style={webStyle.searchIcon} />
          </Box>
        </Box>
       
        <Box sx={webStyle.actionContainer}>
          <button
            data-test-id="addTenant"
            style={webStyle.addPropertiesButton}
            onClick={() => {
              setAddTenantsDialoge({ isEdit: false });
              
            }}
          >
            <img src={addIcon} alt="" style={webStyle.btnIcon} />
            <Typography style={webStyle.typographyTitleText}>
              Add tenant
            </Typography>
          </button>
        </Box>
      </Box>
      <Box>
        <Typography fontFamily={"'Inter', sans-serif"} fontSize={"16px"} color={"#334155"} fontWeight={500} marginBottom={"16px"}>{tenantList.length} results</Typography>
      </Box>
      <Box sx={{ minHeight: totalPages > 1 ? "674px" : "unset" }}>
        <Box sx={webStyle.propertisTable}>
          <Box sx={webStyle.propertisTableHead}>
            <Box sx={webStyle.propertisTableDataHead}>
              <Typography style={webStyle.headerTitle}>
                Tenant name
              </Typography>
              <Typography style={webStyle.headerTitle}>
                # of meters
              </Typography>
            </Box>
            <Typography style={webStyle.headerTitleAction}>
              Actions
            </Typography>
          </Box>
          {tenantList.slice(indexOfFirstItem, indexOfLastItem).map((tenant: { id: number; tenantName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; numberOfMeters: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: any) => (
            <Box sx={webStyle.propertisTableBody} key={tenant.id}>
              <Box
                sx={webStyle.propertisTableDataBody}
                // onClick={() => goToMeter(tenant.id)}
              >
                <Box sx={webStyle.propertyItem}>{tenant.tenantName}</Box>
                <Box sx={webStyle.propertyItem}>
                  {tenant.numberOfMeters}
                </Box>
              </Box>
              <Box sx={webStyle.propertyItemAction}>
                <button
                  data-test-id={`addMeter.${index}`}
                  style={webStyle.addTenantButton}
                  onClick={(event) => addMeter(event,tenant.id)}
                >
                  <img src={addIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    Add meter
                  </Typography>
                </button>
                <button
                  data-test-id={`editTenant.${index}`}
                  style={webStyle.editButton}
                  onClick={() => setAddTenantsDialoge({ isEdit: true })}
                >
                  <img src={editIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                  Edit
                  </Typography>
                </button>
                <button
                  data-test-id={`deleteTenant.${index}`}
                  style={webStyle.deleteButton}
                  onClick={() => setDeleteButtonDialoge(true)}
                  
                >
                  <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                  Delete
                  </Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "5%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Previous Button */}
            <button
              onClick={() => handlePrevPage()}
              data-test-id="previous-button"
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
                marginRight: "4px",
              }}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftRoundedIcon
                sx={
                  currentPage === 1
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>

            {totalPages <= 3 ? (
              [...Array(totalPages).keys()].map((page) => (
                <button
                  key={page + 1}
                  data-test-id={"page-button"}
                  style={{
                    marginRight: "4px",
                    width:
                      currentPage === page + 1
                        ? "24px"
                        : "16px",
                    height: "24px",
                    backgroundColor:
                      currentPage === page + 1
                        ? "#DBF5FF"
                        : "inherit",
                    color:
                      currentPage === page + 1
                        ? "#1A7BA4"
                        : "#64748B",
                    border: "none",
                    textDecoration:
                      currentPage !== page + 1
                        ? "underline"
                        : "none",
                    borderRadius: "8px",
                    fontWeight:
                      currentPage !== page + 1 ? 400 : 700,
                    cursor: "pointer",
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage !== 1 && (
                  <button
                    data-test-id="ellipsis"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() => handlePageChange(1)}
                  >
                    1
                  </button>
                )}

                {currentPage > 3 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage > 2 && (
                  <button
                    data-test-id="middle-page"
                    style={{
                      marginRight: "4px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage - 1)
                    }
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  data-test-id="middle-page2"
                  style={{
                    marginRight: "4px",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#DBF5FF",
                    color: "#1A7BA4",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() =>
                    handlePageChange(currentPage)
                  }
                >
                  {currentPage}
                </button>

                {currentPage < totalPages - 1 && (
                  <button
                    data-test-id="middle-page3"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      color: "#64748B",
                      backgroundColor: "inherit",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage + 1)
                    }
                  >
                    {currentPage + 1}
                  </button>
                )}

                {currentPage < totalPages - 2 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage !== totalPages && (
                  <button
                    data-test-id="last-page"
                    style={{
                      marginRight: "4px",
                      width:
                        currentPage === totalPages
                          ? "24px"
                          : "16px",
                      height: "24px",
                      backgroundColor:
                        currentPage === totalPages
                          ? "#DBF5FF"
                          : "inherit",
                      color:
                        currentPage === totalPages
                          ? "#1A7BA4"
                          : "#64748B",
                      border: "none",
                      textDecoration:
                        currentPage !== totalPages
                          ? "underline"
                          : "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                      fontWeight:
                        currentPage !== totalPages
                          ? 400
                          : 700,
                    }}
                    onClick={() =>
                      handlePageChange(totalPages)
                    }
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              data-test-id="next-page"
              onClick={() => handleNextPage()}
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
              disabled={currentPage === totalPages}
            >
              <KeyboardArrowRightRoundedIcon
                sx={
                  currentPage === totalPages
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>
          </Box>

          <Box sx={{ marginRight: "10px" }}>
            <Typography
              fontFamily={'"Nunito Sans", sans-serif'}
              fontSize={"12px"}
              color={"grey"}
            >
              {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
              {tenantList.length} results
            </Typography>
          </Box>
        </Box>
      )}

      <Dialog 
        open={!!addTenantsDialoge}
        onClose={()=>setAddTenantsDialoge(null)}
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }} data-test-id="addTenantDialogTitle">
          <Typography variant="h6" sx={{ fontWeight: '700', fontFamily: "'Inter', sans-serif", fontSize: '24px' }}>
            {`${addTenantsDialoge?.isEdit ? 'Edit' : 'Add'} tenant`}
          </Typography>
          <IconButton
            onClick={()=>{setAddTenantsDialoge(null)}}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
            data-test-id="addTenantDialogCloseButton"
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: "22px" }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }} data-test-id="addTenantDialogContent">
        <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Formik
              initialValues={{
                tenantName: "",
                meters: [
                  {
                    type: "Electrical",
                    waterType: "",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                  },
                ],
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={() => {
                setValidate(true);
                // createTenant();
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"tenantName"}>
                        Tenant name
                      </label>
                      <input
                        style={{...webStyle.inputElemnt, ...(validate && errors.tenantName ? webStyle.inputElementError : {})}}
                        data-test-id="tenantName"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("tenantName", event.target.value);
                        }}
                      />
                      {validate && errors.tenantName && (
                        <Tooltip
                          open={Boolean(errors.tenantName)}
                          title={errors.tenantName}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((meter, index) => (
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={webStyle.meterTitle}>
                                <Typography sx={webStyle.meterTitleLabel}>
                                  Meter #
                                  {index + 1}
                                </Typography>
                                <button
                                  style={webStyle.removeMeeterButton}
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                </button>
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.radioButtonTitleLabel}
                                  htmlFor={`meters.${index}.type`}
                                >
                                  Type of meter
                                </label>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <input
                                    style={webStyle.radioButtonElemnt}
                                    data-test-id={`meters.${index}.type.electrical`}
                                    name={`meterType.${index}`}
                                    value={"Electrical"}
                                    type="radio"
                                    checked={"Electrical" === values.meters[index].type}
                                    onClick={(event) => {
                                      const element = event.currentTarget as HTMLInputElement;
                                      const value = element.value;
                                      setFieldValue(`meters.${index}.type`, value);
                                    }}
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="electrical"
                                  >
                                    Electrical
                                  </label>
                                </Box>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <input
                                    style={webStyle.radioButtonElemnt}
                                    data-test-id={`meters.${index}.type`}
                                    name={`meterType.${index}`}
                                    value={"Water"}
                                    type="radio"
                                    checked={"Water" === values.meters[index].type}
                                    onClick={(event) => {
                                      const element = event.currentTarget as HTMLInputElement;
                                      const value = element.value;
                                      setFieldValue(`meters.${index}.type`, value);
                                    }}
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="meter"
                                  >
                                    Water
                                  </label>
                                </Box>
                              </Box>
                             
                                {values.meters[index].type === 'Water' && (
                                <Box sx={{ position: 'relative', pl: 4 }}>
                                  <img
                                    src={branch1}
                                    alt="Divider"
                                    className="custom-divider"
                                    style={{ position: 'absolute', left: '13px',top:'-18px', height: '32px' }}
                                  />    
                                  <Box sx={webStyle.radioButtonBlock}>
                                    <input
                                      style={{...webStyle.radioButtonElemnt,height:"16px",width:'16px'}}
                                      id={`meters.${index}.waterType.hot`}
                                      name={`meters.${index}.waterType`}
                                      value="Hot"
                                      type="radio"
                                      checked={values.meters[index].waterType === "Hot"}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                      }}
                                    />
                                      <label style={{...webStyle.radioButtonLabel,fontSize:"14px",marginLeft:"8px"}} htmlFor={`meters.${index}.waterType.hot`}>
                                            <img
                                              src={hotIcon} 
                                              alt="Hot"
                                              width="16" 
                                              height="16"
                                              style={{ marginRight: '4px' }}
                                            />
                                            Hot
                                          </label>
                                        </Box>

                                        <img
                                          src={branch2}
                                          alt="Divider"
                                          className="custom-divider"
                                          style={{ position: 'absolute', left: '13px',top:"7px" }}
                                        />  
                                        <Box sx={webStyle.radioButtonBlock}>
                                    <input
                                      style={{...webStyle.radioButtonElemnt,height:"16px",width:'16px'}}
                                      id={`meters.${index}.waterType.cold`}
                                      name={`meters.${index}.waterType`}
                                      value="Cold"
                                      type="radio"
                                      checked={values.meters[index].waterType === "Cold"}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.waterType`, event.currentTarget.value);
                                      }}
                                    />
                                   <label style={{...webStyle.radioButtonLabel,fontSize:"14px",marginLeft:"8px"}} htmlFor={`meters.${index}.waterType.hot`}>
                                            <img
                                              src={coldIcon} 
                                              alt="Cold"
                                              width="16" 
                                              height="16"
                                              style={{ marginRight: '4px' }}
                                            />
                                            Cold
                                          </label>
                                  </Box>
                                </Box>
                              )}

                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.meterNumner`}
                                >
                                 Meter number
                                </label>
                                <input
                                  style={webStyle.inputElemnt1}
                                  data-test-id={`meters.${index}.meterNumner`}
                                  type="text"
                                  onChange={(event) => {
                                    setFieldValue(`meters.${index}.meterNumner`, event.target.value);
                                  }}
                                />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReading`}
                                  >
                                    Last reading
                                  </label>
                                  <input
                                    style={webStyle.inputElemnt1}
                                    data-test-id={`meters.${index}.lastReading`}
                                    type="text"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReading`, event.target.value);
                                    }}
                                  />
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReadingDate`}
                                  >
                                    Date of last reading
                                  </label>
                                  <StyledInput
                                    data-test-id={`meters.${index}.lastReadingDate`}
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.lastReadingDate`, event.target.value);
                                    }}
                                    style={!values.meters[index].lastReadingDate ? { color: '#94A3B8' } : undefined}
                                  />
                                  <img
                                    src={caledarIcon}
                                    alt=""
                                    style={webStyle.calendarIcon as any}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box sx={webStyle.fieldControl1}>
                              <button
                                style={webStyle.addNewMeterButton}
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    // type: "",
                                    meterNumner: "",
                                    lastReading: "",
                                    lastReadingDate: "",
                                  });
                                }}
                              >
                                  <AddIcon style={{...webStyle.btnIcon,color:"#1A7BA4"}} />
                                <Typography style={webStyle.typographyTitleText1}>
                                Add meter
                                </Typography>
                              </button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                      <Box sx={webStyle.fieldControlGroup}>
                        <button
                          style={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          type="button"
                          onClick={() => {
                            // goTenantsPage();
                          }}
                        >
                         Cancel
                        </button>
                        <button
                          style={webStyle.submitButton}
                          data-test-id="submitBtn"
                          type="button"
                          onClick={() => {
                            // setTenantData({ tenantName: values?.tenantName });
                            // setMetersData(values?.meters);
                            setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                            // goPropertieesPage();
                          }}
                        >
                         Save
                        </button>
                      </Box>
                    </Box>
                    {console.log({ values })}
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>


        </DialogContent>
      </Dialog>



      <Dialog
              open={deleteButtonDialoge}
              onClose={()=>setDeleteButtonDialoge(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: "633px", 
                  height: "240px", 
                  padding: "40px 32px 32px 32px", 
                  gap: "10px", 
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "600", paddingBottom: "8px",fontFamily:"'Inter', sans-serif", fontSize:'20px',whiteSpace:"nowrap"}}
        >
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#64748B", paddingBottom: "16px",fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
          >
            This action can’t be undone and all the data stored in this account
            won’t be accessible anymore.
          </DialogContentText>
          <Divider /> 
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <Button
            onClick={()=>setDeleteButtonDialoge(false)}
            variant="outlined" 
            sx={{...webStyle.cancelButton,height:"40px"}}
          >
            Cancel
          </Button>
          <Button
            // onClick={deleteReport}
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />} 
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    );
  };

  const StyledInput = styled(InputBase)(() => ({
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

  const webStyle = {
    propertiesContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "rgba(248, 250, 252, 1)",
    },
    tenantTitleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "20px 0px",
      justifyContent: "space-between",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px",
    },
    searchContainer: {
      width: "340px",
      display: "flex",
      flexDirection: "row",
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
    },
    tenantActionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      backgroundColor: 'rgba(248, 250, 252, 1)',
      borderRadius: '12px',
    },
    propertisTableDataHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      width: "calc(100% - 340px)",
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    propertisTableDataBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      width: "calc(100% - 340px)",
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchElement: {
      padding: "10px 40px 10px 10px",
      height: '44px',
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 16,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    backButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 0px",
    },
    addPropertiesButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "7px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 12px",
    },
    headerTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "50%",
      overflow: "hidden",
      margin: "12px 0",
    },
    headerTitleAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "340px",
      overflow: "hidden",
      margin: "12px 0",
    },
    addTenantButton: {
      backgroundColor: "#1A7BA4",
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "none",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
    },
    propertyItem: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "50%",
      margin: "13px 0",
      alignItems: "center",
      display: "flex",
      wordBreak: 'break-word',
    },
    propertyItemAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "340px",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "13px 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tenantTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      color: "#0F172A",
      wordBreak: 'break-word'
    },
    tenantSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B",
      wordBreak: 'break-word',
    },
    typographyTitleText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginLeft: "6px",
      overflow: "hidden",
    },
    typographyText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    addTenantsContainer: {
      display: "flex",
      flexDirection: "row",
    },
    addTenantsBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    addTenantFormContainer: {
      maxWidth: "600px",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      wordBreak: 'break-word',
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      wordBreak: 'break-word',
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px",
      position: "relative",
    },
    mainFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    arrayFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px",
      position: "relative",
    },
    switchTitle: {
      alignItems: "center",
      display: "flex",
    },
    radioButtonTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
      margin: "10px 0px",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
    },
    radioButtonLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      alignItems: "center",
      display: "flex",
    },
    radioButtnBlock: {
      display: "flex",
      flexDirection: "row",
      margin: "10px 0px",
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
    },
    inputElementError: {
      border: "1px solid #F87171",
    },
    radioButtonElemnt: {
      height: "20px",
      width: "20px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
    },
    numberFieldControlButton: {
      color: "#1A7BA41A",
      border: "none",
      width: "33%",
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    meterBlcok: {
      border: "1px solid #1A7BA44D",
      backgroundColor: "#1A7BA40F",
      padding: "16px",
      borderRadius: "12px",
      margin: "20px 0px",
    },
    meterTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    meterTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineheight: "24px",
      color: "#0F172A",
    },
    addNewMeterButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "inherit",
      color: "#1A7BA4",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    removeMeeterButton: {
      height: "36px",
      width: "46px",
      border: "none",
      backgroundColor: "inherit",
    },
    typographyTitleText1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    calendarIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 13,
      backgroundColor: '#FFFFFF',
      pointerEvents: 'none',
    },
    radioButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    radioButtonElement: {
      marginRight: '8px',
    },
    confirmDelete:{
      padding: "6px 12px",
      borderRadius: "5px",
      textTransform: "none",
      backgroundColor:"white",
      color:"#DC2626",
      border:"1px solid #DC2626",
      fontFamily:"'Inter', sans-serif",
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
  };
 