import React, {useState } from 'react';
import { Box, Grid,Typography,Button, Select, MenuItem } from '@mui/material';

  export interface Props {
    handleCompanyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

  const styles = {
    container: {
      backgroundColor:"#F8FAFC",
      marginTop:"24px"
    },
    inputField: (isEditing: boolean) => ({
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      gap: "8px",
      width: '100%',
      marginBottom: '16px',
      padding: '10px 12px',
      backgroundColor: isEditing ? "#FFFFFF" : "#F1F5F9", // Change to white when editing
      borderRadius: '8px',
      border: "1px solid #CBD5E1",
      outline: 'none',
      color: "#0F172A",
      fontWeight: "400",
      height: '56px',
    }),
    inputLabel: {
      fontWeight: "700",
      marginBottom:"4px",
      fontFamily: "'Inter', sans-serif",
      fontSize:"14px",
      color:"#334155"
    },
    buttonWrapper: {
      display: 'flex',
      gap: '16px',
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px 0 0",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 16px",
      textTransform: 'none',
      '&:hover': {
        background: "#FFFFFF",
      }
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px 0 0",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 16px",
      textTransform: 'none',
      '&:hover': {
        background: "#1A7BA4",
      }
    },
    select: {
      fontFamily: "'Inter', sans-serif",
      height: '56px',
      fontSize: '16px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      color: '#0F172A',
      '& .MuiSelect-select': {
        fontFamily: "'Inter', sans-serif",
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-disabled': {
        color: '#0F172A',
        backgroundColor: '#F1F5F9',
        '& .MuiInputBase-input': {
          WebkitTextFillColor: '#0F172A', // Override -webkit-text-fill-color
          color: '#0F172A',
        },
      }
    },
    selectOption: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      color: '#0F172A',
      padding: '8px 12px',
      margin: '0 8px 12px',
      borderRadius: '8px',
      '&:last-of-type': {
        margin: '0 8px',
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      }
    },
  };

  const cities = [
    {
      id: 1,
      value: "New York City",
    },
    {
      id: 2,
      value: "City 2",
    },
    {
      id: 3,
      value: "City 3",
    },
    {
      id: 4,
      value: "City 4",
    },
    {
      id: 5,
      value: "City 5",
    },
  ];

  const states = [
    {
      id: 1,
      value: "New York",
    },
    {
      id: 2,
      value: "State 2",
    },
    {
      id: 3,
      value: "State 3",
    },
    {
      id: 4,
      value: "State 4",
    },
    {
      id: 5,
      value: "State 5",
    },
  ];

export const CustomerDetails = ({handleCompanyChange }: Props) => {
  const [isEditing, setIsEditing] = useState(false);   
 
  const handleEditClick = () => {
    setIsEditing((prev) => !prev); 
  };

    return (
      <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={styles.inputLabel}>Company name</Typography>
          <input type="text" defaultValue="Acme Group" style={styles.inputField(isEditing)} disabled={!isEditing} 
              onChange={handleCompanyChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={styles.inputLabel}>Mailing address</Typography>
          <input type="text" defaultValue="New Hyde Park, NY 11040" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={5} >
          <Typography sx={styles.inputLabel}>City</Typography>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            data-test-id="city"
            sx={{...styles.select, width: "460px", maxWidth: "100%"}}
            defaultValue={cities[0].id}
            disabled={!isEditing}
          >
            {cities.map((c) => (
              <MenuItem value={c.id} sx={styles.selectOption}>
                {c.value}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={5} >
          <Typography sx={styles.inputLabel}>State</Typography>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            data-test-id="state"
            sx={{...styles.select, width: "460px", maxWidth: "110%"}}
            defaultValue={states[0].id}
            disabled={!isEditing}
          >
            {states.map((c) => (
              <MenuItem value={c.id} sx={styles.selectOption}>
                {c.value}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography sx={{...styles.inputLabel,marginLeft:"27px"}}>ZIP code</Typography>
          <input type="text" defaultValue="11033" style={{...styles.inputField(isEditing),width:"135px",maxWidth:"100%",marginLeft:"27px"}} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Phone number</Typography>
          <input type="text" defaultValue="1-212-456-7890" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Fax number</Typography>
          <input type="text" defaultValue="1-907-555-1234" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography sx={styles.inputLabel}>Email</Typography>
          <input type="email" defaultValue="johndoe@example.com" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.inputLabel}>Contact person</Typography>
          <input type="text" defaultValue="John Doe" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={styles.inputLabel}>Title of contact person</Typography>
          <input type="text" defaultValue="Job Title" style={styles.inputField(isEditing)} disabled={!isEditing} />
        </Grid>
      </Grid>

      <Box 
        display="flex" 
        justifyContent="flex-end"
      >
      {isEditing ? (
        <Box sx={styles.buttonWrapper}>
          <Button 
            sx={styles.cancelButton}
            onClick={() => handleEditClick()}
          >
            Cancel
          </Button>
          <Button 
            sx={styles.submitButton}
            onClick={() => handleEditClick()}
          >
            Save
          </Button>
        </Box>
        ) : (
          <Button 
            sx={styles.submitButton}
            onClick={() => handleEditClick()}
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
    );
  };