import React from "react";
// Customizable Area Start
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography, Tabs, Tab, Box, IconButton } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CustomerDetails} from "../../../components/src/CustomerDetails.web"
import {CustomerProperties} from "./CustomerProperties.web"
import {CustomerTenants} from "./CustomerTenants.web"
import {CustomerMeters} from "./CustomerMeters.web"

// Customizable Area End

import CustomersPageController, {
  Props,
  configJSON,
} from "./CustomersPageController";
import { borderRadius } from "react-select/src/theme";
// Customizable Area Start

// Customizable Area End
export default class CustomersPageDetails extends CustomersPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.container}>
     <Box marginBottom={"24px"}>
     <Breadcrumbs 
        aria-label="breadcrumb" 
        sx={webStyle.breadcrumb} 
        separator={<NavigateNext fontSize="small" sx={{ color: '#1A7BA4' }} />}
      >
        <Link sx={webStyle.breadcrumbsText(true)} href="/">
          Customers
        </Link>
        <Typography sx={webStyle.breadcrumbsText(this.state.tabIndex >= 1)}>
          Acme Group
        </Typography>
        {this.state.tabIndex === 1 && (
          <Typography sx={webStyle.breadcrumbsText(this.state.selectedProperty || this.state.isAddMeterOpen)}>Properties</Typography>
        )}
        {this.state.tabIndex === 1 && this.state.selectedProperty && (
          <Typography sx={webStyle.breadcrumbsText(this.state.tabIndex === 1 && this.state.isAddMeterOpen)}>Tenants</Typography>
        )}
         {this.state.tabIndex === 1 && this.state.isAddMeterOpen && (
            <Typography sx={webStyle.breadcrumbsText()}>Meters</Typography>
          )}
      </Breadcrumbs>
     </Box>

     <Box sx={webStyle.header}>
  <Box sx={webStyle.details}>
    <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "flex-start" }}>
      <IconButton onClick={this.goBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h5" sx={webStyle.companyNameTitle}>
        {this.state.selectedProperty && this.state.isAddMeterOpen 
          ? "Meters" 
          : this.state.selectedProperty 
          ? "Tenants" 
          : "Acme Group."}
    </Typography>
    </Box>
    
    {!this.state.selectedProperty && (
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: "95%", marginLeft: "7px", color: "#64748B" }}>
        <Box sx={webStyle.contactInfo}>
          <EmailOutlinedIcon sx={{ marginBottom: "3px" }} />
          <Typography sx={webStyle.contactInfoText}>acme.group@company.com</Typography>
        </Box>
        <Box sx={webStyle.contactInfo}>
          <PhoneOutlinedIcon />
          <Typography sx={webStyle.contactInfoText}>+1 (197) 137-1362</Typography>
        </Box>
        <Box sx={webStyle.contactInfo}>
          <LocationOnOutlinedIcon />
          <Typography sx={webStyle.contactInfoText}>Bd. Brooklyn Avenue, New York, 11023</Typography>
        </Box>
      </Box>
    )}
  </Box>
</Box>

      {!this.state.selectedProperty && (
        <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="company details tabs" sx={webStyle.tabs}>
        <Tab 
              label="Company details" 
              sx={{ 
                fontWeight: this.state.tabIndex === 0 ? 700 : 400, 
                color: this.state.tabIndex === 0 ? '#1A7BA4' : '#64748B',
                textTransform: 'none' 
              }} 
            />
            <Tab 
              label="Properties" 
              sx={{ 
                fontWeight: this.state.tabIndex === 1 ? 700 : 400, 
                color: this.state.tabIndex === 1 ? '#1A7BA4' : '#64748B',
                textTransform: 'none' 
              }} 
            />
            <Tab 
              label="Reports" 
              sx={{ 
                fontWeight: this.state.tabIndex === 2 ? 700 : 400, 
                color: this.state.tabIndex === 2 ? '#1A7BA4' : '#64748B',
                textTransform: 'none' 
              }} 
            />
        </Tabs>
      )}
      

      <Box role="tabpanel" hidden={this.state.tabIndex !== 0}>
        <CustomerDetails 
            handleCompanyChange ={this.handleCompanyChange}
        />
      </Box>
      <Box role="tabpanel" hidden={this.state.tabIndex !== 1}>
      {this.state.selectedProperty && this.state.isAddMeterOpen ? (
    <CustomerMeters 
    meterList={this.state.meterList}
    />
  ) : this.state.selectedProperty ? (
    <CustomerTenants
      tenantList={this.state.tenantList}
      addMeter={this.handleAddMeterClick} 
    />
  ) : (
    <CustomerProperties
      searchProperties={this.searchProperties}
      propertiesList={this.state.propertiesList}
      filterProperties={this.filterProperties}
      addTenant={this.addTenant}
    />
  )}
      </Box>
      <Box role="tabpanel" hidden={this.state.tabIndex !== 2}>
        <Typography>Reports content goes here.</Typography>
      </Box>
          

    </Box>
      // Customizable Area Start
    );
  }
}
// Customizable Area Start


const webStyle = {
  container: {
    padding: '40px 50px',
    backgroundColor:"#F8FAFC",
    fontFamily: "'Inter', sans-serif",
    height: '100%',
  },
  breadcrumb: {
    marginBottom: '8px',
    fontFamily: "'Inter', sans-serif",
  },
  breadcrumbCompanyName: {
    fontFamily: "'Inter', sans-serif",
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    marginLeft:"-7px"
  },
  details: {
    display: 'flex',
    flexDirection:'column',
    gap: '16px',
    width:"100%",
    fontFamily: "'Inter', sans-serif",
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
  },
  contactInfoText: {
    fontFamily: "'Inter', sans-serif",
  },
  tabs: {
    borderBottom: '1px solid #e0e0e0',
    marginTop: '16px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    }
  },
  companyNameTitle: {
    color: 'rgba(15, 23, 42, 1)',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
  },
  breadcrumbsText: (isActive?: boolean) => ({
    color: isActive ? '#1A7BA4' : '#0F172A',
    fontWeight: isActive ? 400 : 500,
    textDecoration: isActive ? 'underline' : 'none',
    fontFamily: '"Inter", sans-serif',
  }),
};
// Customizable Area End
