import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Property {
  id: string;
  propertysName: string;
  propertysAddress: string;
  countTenant: string | number;
  countMeter: string | number;
}
interface Company {
  id: string;
  companyName: string;
  serviceAdress: string;
  createdAT: string | null;
  lastLoggedIn: string | null;
}

interface Tenant {
  
  id: number;
  tenantName: string;
  numberOfMeters: number;
}

interface Meter {
  id: number;
  meterNumber: string;
  type: string;
  lastReading:number;
  dateOfLastReading:string;
}

interface S {
  propertiesList:Property[]
  currentPage:number;
  itemsPerPage:number;
  totalPages:number;
  currentcompany: Company[];
  firstItemIndex:number,
  lastItemIdex:number,
  tabIndex:number,
  selectedProperty:boolean
  // Customizable Area Start
  companyList: Company[]
  tenantList: Tenant[]
  isAddMeterOpen:boolean
  meterList:Meter[]
  token:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class companyPageController extends BlockComponent<Props, S, SS> {
  companyListCallId :any
  companyDetailsCallId :any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.companyListCallId = "";
    this.companyDetailsCallId="";

    this.state = {
      propertiesList: [
        {
          id: "1",
          propertysName: "Abstergo Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "2",
          propertysName: "Big Kahuna",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "3",
          propertysName: "Biffco Enterprises Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "4",
          propertysName: "Acme Co.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "5",
          propertysName: "Big Kahuna",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "6",
          propertysName: "Biffco Enterprises",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "7",
          propertysName: "Binford Ltd.",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "8",
          propertysName: "Acme Co.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "9",
          propertysName: "Barone LLC.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "10",
          propertysName: "Binford Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "11",
          propertysName: "Abstergo Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "12",
          propertysName: "Big Kahuna",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "13",
          propertysName: "Biffco Enterprises Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "14",
          propertysName: "Acme Co.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "15",
          propertysName: "Big Kahuna",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "16",
          propertysName: "Biffco Enterprises",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "17",
          propertysName: "Binford Ltd.",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "18",
          propertysName: "Acme Co.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "19",
          propertysName: "Barone LLC.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "20",
          propertysName: "Binford Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 30,
        },
      ],

      tenantList : [
        { id: 1, tenantName: "John Doe", numberOfMeters: 2 },
        { id: 2, tenantName: "Jane Smith", numberOfMeters: 1 },
        { id: 3, tenantName: "Michael Johnson", numberOfMeters: 3 },
        { id: 4, tenantName: "Emily Davis", numberOfMeters: 2 },
        { id: 5, tenantName: "David Brown", numberOfMeters: 4 },
        { id: 6, tenantName: "Sarah Wilson", numberOfMeters: 1 },
        { id: 7, tenantName: "Chris Taylor", numberOfMeters: 2 },
        { id: 8, tenantName: "Amanda Martinez", numberOfMeters: 3 },
        { id: 9, tenantName: "James Anderson", numberOfMeters: 2 },
        { id: 10, tenantName: "Jessica Thomas", numberOfMeters: 1 },
        { id: 11, tenantName: "Jessica Thomas #2", numberOfMeters: 1 }
      ],

      meterList:[
        {
          id: 1,
          meterNumber: 'MN1001',
          type: 'Water (hot)',
          lastReading: 120,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 2,
          meterNumber: 'MN1002',
          type: 'Electrical',
          lastReading: 450,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 3,
          meterNumber: 'MN1003',
          type: 'Water (cold)',
          lastReading: 230,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 4,
          meterNumber: 'MN1004',
          type: 'Water (cold)',
          lastReading: 175,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 5,
          meterNumber: 'MN1005',
          type: 'Electrical',
          lastReading: 320,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 6,
          meterNumber: 'MN1006',
          type: 'Water (hot)',
          lastReading: 210,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 7,
          meterNumber: 'MN1007',
          type: 'Water (hot)',
          lastReading: 90,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 8,
          meterNumber: 'MN1008',
          type: 'Electrical',
          lastReading: 500,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 9,
          meterNumber: 'MN1009',
          type: 'Water (hot)',
          lastReading: 300,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 10,
          meterNumber: 'MN1010',
          type: 'Water (cold)',
          lastReading: 140,
          dateOfLastReading: '15/12/2024',
        },
        {
          id: 11,
          meterNumber: 'MN1010',
          type: 'Water (hot)',
          lastReading: 140,
          dateOfLastReading: '15/12/2024',
        },
      ],

      companyList:[],
      currentPage:1,
      itemsPerPage:10,
      totalPages: 0,
      currentcompany: [],
      firstItemIndex:1,
      lastItemIdex:10,
      tabIndex:0,
      selectedProperty:false,
      isAddMeterOpen:false,
      token:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    console.log({apiRequestCallId11:apiRequestCallId,responseJson});
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.companyListCallId && responseJson) {
       this.getCompanyList(responseJson);
    }
    if (apiRequestCallId === this.companyDetailsCallId) {
        this.setCompanyDetails(responseJson);
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchcompany = (search: string) => {

  }

  filtercompany = () => {

  }

  addcompany = () => {
    this.props.navigation.navigate("AddcompanyPage");
  }

  addTenant = (event: any,value: any) => {
          this.setState({selectedProperty:true})
   }

  viewDetails = (id: string) => {
    
  }

  deletePropeerties = (id: string) => {
    
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');

    if (userRole !== 'admin') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }
    await this.getToken();
    await this.customerProperties();
    await Promise.all([this.updatePagination()]);
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.companyList !== this.state.companyList
      ) {
      await Promise.all([ this.updatePagination()]);
    }
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.companyList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, companyList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentcompany = companyList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(companyList.length / itemsPerPage);

    if (indexOfLastItem > companyList.length ) {
        indexOfLastItem = companyList.length;
    }

    this.setState({
      currentcompany,
      totalPages,
      firstItemIndex:indexOfFirstItem,
      lastItemIdex:indexOfLastItem,
    });
  };

  handleTabChange = (event: any, newValue: any) => {
    this.setState({tabIndex:newValue});
  };

  handleCompanyChange = (event:any)=>{
  }

  searchProperties = ()=>{

  }

  filterProperties = ()=>{

  }
  handleAddMeterClick = () => {
    this.setState({ isAddMeterOpen: true });
  };

  goToCustomerDetails = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      `CustomerDetailsPage`
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToCustomersPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      `CustomersPage`
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goBack = () => {
    if (this.state.tabIndex === 2) {
      this.setState({ tabIndex: 0 });
    }

    if (this.state.tabIndex === 1 && this.state.isAddMeterOpen) {
      this.setState({ isAddMeterOpen: false, selectedProperty: true });

      return;
    }

    if (this.state.tabIndex === 1 && this.state.selectedProperty) {
      this.setState({ selectedProperty: false });

      return;
    }

    if (this.state.tabIndex === 1) {
      this.setState({ tabIndex: 0 });

      return;
    }

    if (this.state.tabIndex === 0) {
      this.goToCustomersPage();
    }
  }

  customerProperties = () => {
    
    const header = {
        "Content-Type": configJSON.getPropertiesApiContentType,
        token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyListCallId = requestMessage.messageId;

    const requestUrl = configJSON.customerPropertiesEndPoint;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getPropertiesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getCompanyList = (responseJson: any) => {
  const companyData: Company[] = responseJson.data.map((item: any): Company => {
    const rawDate = item.attributes.created_at ? new Date(item.attributes.created_at) : null;
    const formattedDate = rawDate
      ? `${rawDate.getDate().toString().padStart(2, '0')}/${(rawDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}/${rawDate.getFullYear()}`
      : null;

    return {
      id: item.attributes.id || "",
      companyName: item.attributes.company_name || "",
      serviceAdress: item.attributes.mailing_address || "",
      createdAT: formattedDate, 
      lastLoggedIn: null
    };
  });

  this.setState({
    companyList: companyData
  });
};




companyDetails = (id:string) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.companyDetailsCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.companyDetailsEndPoint}/${id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPropertiesApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

};

setCompanyDetails =(responseJson: any)=>{
      this.goToCustomerDetails();
}
  // Customizable Area End
}
