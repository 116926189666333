import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Tenant {
  id: string;
  tenantName: string;
  countMeter: number;
}

interface S {
  token: string;
  propertyId: number;
  currentProperty: any;
  tenantList: Tenant[];
  // Customizable Area Start
  firstItemIndex: number;
  lastItemIdex: number;
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  currentTenants: Tenant[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TenantPageController extends BlockComponent<Props, S, SS> {
  apiGetTenantsListCallId: string = "";
  apiDeleteTenantCallId: string = "";
  apiShowPropertyCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      propertyId: 0,
      currentProperty: {},
      tenantList: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 1,
      firstItemIndex: 1,
      lastItemIdex: 10,
      currentTenants: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetTenantsListCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ 
              tenantList: responseJson.data
                .map(({ attributes }: any) => ({ tenantName: attributes.name, id: attributes.id }) ) 
            })
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.apiDeleteTenantCallId) {
          if (responseJson && responseJson.message && responseJson.message === "Successfully Deleted!") {
            this.getTenantsList();
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiShowPropertyCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({  currentProperty: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');

    if (userRole !== 'user') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }

    await this.getToken();
    if (this.state.propertyId !== 0) {
      await this.getPropertyById(this.state.propertyId);
    }
    await this.getTenantsList();
  }

  async componentDidUpdate(_: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.tenantList !== this.state.tenantList
      ) {
      await Promise.all([ this.updatePagination()]);
    }
  }

  getTenantsList = () => {
    const header = {
      "Content-Type": configJSON.getTenantsApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTenantsListCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTenantsApiEndPoint + `/${this.state.propertyId}/tenants`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTenantsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPropertyById = (propertyId: number) => {
    const header = {
      "Content-Type": configJSON.showPropertyApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowPropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showPropertyApiEndPoint + "/" + propertyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showPropertyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchProperties = (search: string) => {

  }

  addTenant = () => {
    setStorageData('propertyId', this.state.propertyId);

    this.props.navigation.navigate("AddTenantPage");
  }

  goToMeter = (id: string) => {
    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', id);

    this.props.navigation.navigate("MeterPage");
  }

  addMeter = (id: string) => {

    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', id);

    this.props.navigation.navigate("AddTenantPage");
  }

  editTenant = (id: string) => {
    setStorageData('propertyId', this.state.propertyId);
    setStorageData('tenantId', id);

    this.props.navigation.navigate("AddTenantPage");
  }

  deleteTenant = (id: string) => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteTenantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteTenantApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTenantApiEndPoint + `/${this.state.propertyId}/tenants/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  goBack = () => {
    this.props.navigation.navigate("PropertiesPage");
  }

  goToLogInPage = () => {
    this.props.navigation.navigate("LogInPage");
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.tenantList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, tenantList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTenants = tenantList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(tenantList.length / itemsPerPage) || 1;

    if (currentTenants.length === 0) {
      this.handlePageChange(totalPages);
    }

    if (indexOfLastItem > tenantList.length ) {
        indexOfLastItem = tenantList.length;
    }

    this.setState({
      currentTenants,
      totalPages,
      firstItemIndex: indexOfFirstItem,
      lastItemIdex: indexOfLastItem,
    });
  };

  getToken = async () => {
    let token = await getStorageData("token");
    let propertyId = await getStorageData("propertyId");
    this.setState({ token: token, propertyId: propertyId });
  };
  // Customizable Area End
}
