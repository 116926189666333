import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  leftArrowIcon,
} from "./assets";
// Customizable Area End

import MeterPageController, { Props, configJSON } from "./MeterPageController";
// Customizable Area Start

// Customizable Area End
export default class MeterPage extends MeterPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.propertiesContainer}>
        <Box sx={webStyle.tenantTitleContainer}>
          <Box sx={webStyle.tenantActionContainer}>
            <button
              data-test-id="goBack"
              style={webStyle.backButton}
              onClick={() => {
                this.goBack();
              }}
            >
              <img src={leftArrowIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.backToTenantText}
              </Typography>
            </button>
            <Typography sx={webStyle.tenantTitle}>
              {this.state.currentProperty.property_name}
            </Typography>
            <Typography sx={webStyle.tenantSubTitle}>
              {this.state.currentProperty.service_address}
            </Typography>
          </Box>
        </Box>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.searchPlaceholderText}
                onChange={(event) => {
                  this.searchProperties(event.target.value);
                }}
              />
              <img src={searchIcon} alt="" style={webStyle.searchIcon} />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button
              data-test-id="addMeter"
              style={webStyle.addPropertiesButton}
              onClick={() => {
                this.addMeter();
              }}
            >
              <img src={addIcon} alt="" style={webStyle.btnIcon} />
              <Typography style={webStyle.typographyTitleText}>
                {configJSON.addMeterText}
              </Typography>
            </button>
          </Box>
        </Box>
        <Box sx={webStyle.propertisTable}>
          <Box sx={webStyle.propertisTableHead}>
            <Typography style={webStyle.headerTitle}>
              {configJSON.meterNumberText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.typeText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.lastReadingText}
            </Typography>
            <Typography style={webStyle.headerTitle}>
              {configJSON.lastReadingDateText}
            </Typography>
            <Typography style={webStyle.headerTitleAction}>
              {configJSON.actionsText}
            </Typography>
          </Box>
          {this.state.meterList.map((meter, index) => (
            <Box sx={webStyle.propertisTableBody} key={meter.id}>
              <Box sx={webStyle.propertyItem}>{meter.meterNumber}.</Box>
              <Box sx={webStyle.propertyItem}>{meter.type}</Box>
              <Box sx={webStyle.propertyItem}>{meter.lastRiding}</Box>
              <Box sx={webStyle.propertyItem}>{meter.dateLastRiding}</Box>
              <Box sx={webStyle.propertyItemAction}>
                <button
                  data-test-id={`editMeter.${index}`}
                  style={webStyle.editButton}
                  onClick={() => {
                    this.editMeter(meter.id);
                  }}
                >
                  <img src={editIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.editText}
                  </Typography>
                </button>
                <button
                  data-test-id={`deleteMeter.${index}`}
                  style={webStyle.deleteButton}
                  onClick={() => {
                    this.deleteMeter(meter.id);
                  }}
                >
                  <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.deleteText}
                  </Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(248, 250, 252, 1)",
    padding: "0px 30px",
    minHeight: "100%",
  },
  tenantTitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "space-between",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantActionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: 'rgba(248, 250, 252, 1)',
    borderRadius: '12px',
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      borderRadius: '0 0 12px 12px',
    }
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  backButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 6px",
  },
  addPropertiesButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 10px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc( 25% - 45px)",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "180px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc( 25% - 45px)",
    wordBreak: 'break-word',
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "180px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    color: "#0F172A",
    wordBreak: 'break-word',
  },
  tenantSubTitle: {
    marginTop: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    wordBreak: 'break-word',
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    marginLeft: "6px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
};
// Customizable Area End
