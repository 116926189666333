import React from "react";
// Customizable Area Start

import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { addIcon, removeIcon } from "./assets";

// Customizable Area End

import SettingsPageController, {
  Props,
  configJSON,
} from "./SettingsPageController";
// Customizable Area Start
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Customizable Area End
export default class SettingsPage extends SettingsPageController {
  constructor(props: Props) {
    super(props);
  }

  companyDetailSchema = () => {
    return Yup.object().shape({
      companyName: Yup.string().required("Company Name is required"),
      address: Yup.string().required("Mailing Address is required"),
      zipCode: Yup.string()
        .required("Please enter your zip")
        .matches(/^\d{4,7}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .test(
          "length",
          "Zip code needs to be between 4 and 7 digits",
          (val: any) => val?.length >= 4 && val?.length <= 7
        )
        .typeError("Please enter a valid zip code"),
      state: Yup.number().required("*State is required!"),
      city: Yup.number().required("*City is required!"),
      phoneNumber: Yup.string().required("Phone number is required"),
      faxNumber: Yup.string().required("Fax number is required"),
      primaryEmail: Yup.string()
        .email("Invalid email format")
        .required("Primary email is required"),
      contactPerson: Yup.string().required("Contact person is required"),
      titleOfContactPerson: Yup.string().required(
        "Title of contact person is required"
      ),
      alternativeEmail: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email("Invalid email format")
              .required("Phon number is required"),
            useFor: Yup.string().required("Phon number is required"),
          })
        )
        .notRequired(),
    });
  };

  questionSchema = () => {
    return Yup.object().shape({
      zipCode: Yup.string()
        .required("Please enter your zip")
        .matches(/^\d{4,7}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .test(
          "length",
          "Zip code needs to be between 4 and 7 digits",
          (val: any) => val?.length >= 4 && val?.length <= 7
        )
        .typeError("Please enter a valid zip code"),
      name: Yup.string().required("Name is required"),
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    });
  };

  companyDetailsForm = () => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <Formik
        initialValues={{
          companyName: "",
          address: "",
          zipCode: "",
          city: '',
          state: '',
          phoneNumber: "",
          faxNumber: "",
          primaryEmail: "",
          contactPerson: "",
          titleOfContactPerson: "",
          alternativeEmail: [] as { email: string, useFor: string[] }[],
        }}
        validateOnMount={true}
        validateOnChange={true}
        validationSchema={this.companyDetailSchema}
        onSubmit={() => {
          this.setValidate(true);
        }}
        data-test-id="updateCompanyDetails"
      >
        {({ setFieldValue, values, errors, handleSubmit }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"companyName"}>
                  Company name
                </label>
                <input
                  style={webStyle.inputElemnt}
                  data-test-id="companyName"
                  type="text"
                  value={values?.companyName}
                  onChange={(event) => {
                    setFieldValue("companyName", event.target.value);
                  }}
                />
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.addressFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"address"}>
                    Mailing address
                  </label>

                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="address"
                    type="text"
                    value={values?.address}
                    onChange={(event) => {
                      setFieldValue("address", event.target.value);
                    }}
                  />
                </Box>
                <Box sx={webStyle.zipCodeFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"zipCode"}>
                    {configJSON.zipCodeText}
                  </label>

                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="zipCode"
                    type="text"
                    value={values?.zipCode}
                    onChange={(event) => {
                      setFieldValue("zipCode", event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"city"}>
                    City
                  </label>
                  <Select
                    value={values?.city}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="city"
                    sx={webStyle.select}
                    onChange={(event) => {
                      setFieldValue("city", event.target.value);
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <Box component="div" sx={webStyle.selectPlaceholder}>Select one</Box>
                      }

                      return selected;
                    }}
                  >
                    {this.state.cities.map((c) => (
                      <MenuItem value={c.id} sx={webStyle.selectOption}>
                        {c.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"state"}>
                    State
                  </label>
                  <Select
                    value={values?.state}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="state"
                    sx={webStyle.select}
                    onChange={(event) => {
                      setFieldValue("state", event.target.value);
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <Box component="div" sx={webStyle.selectPlaceholder}>Select one</Box>
                      }

                      return selected;
                    }}
                  >
                    {this.state.states.map((s) => (
                      <MenuItem value={s.id} sx={webStyle.selectOption}>
                        {s.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"phoneNumber"}>
                  Phone number
                </label>

                <input
                  style={webStyle.inputElemnt}
                  data-test-id="phoneNumber"
                  type="text"
                  value={values?.phoneNumber}
                  onChange={(event) => {
                    setFieldValue("phoneNumber", event.target.value);
                  }}
                />
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"faxNumber"}>
                  Fax number
                </label>

                <input
                  style={webStyle.inputElemnt}
                  data-test-id="faxNumber"
                  type="text"
                  value={values?.faxNumber}
                  onChange={(event) => {
                    setFieldValue("faxNumber", event.target.value);
                  }}
                />
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"primaryEmail"}>
                  Primary email
                </label>

                <input
                  style={webStyle.inputElemnt}
                  data-test-id="primaryEmail"
                  type="email"
                  value={values?.primaryEmail}
                  onChange={(event) => {
                    setFieldValue("primaryEmail", event.target.value);
                  }}
                />
              </Box>
              <FieldArray name="alternativeEmail">
                {(arrayHelpers: any) => (
                  <>
                    {values.alternativeEmail.map((_, index) => (
                      <Box sx={webStyle.fieldGroupContral}>
                        <Box sx={webStyle.columnFieldControl}>
                          <label
                            style={webStyle.inputLabel}
                            htmlFor={"contactPerson"}
                          >
                            Alternative Email #{index + 1}
                          </label>
                          <input
                            style={webStyle.inputElemnt}
                            data-test-id={`alternativeEmail.${index}. email`}
                            type="text"
                            value={values?.alternativeEmail[index].email}
                            onChange={(event) => {
                              const updatedEmailArray = [...values.alternativeEmail];
                              updatedEmailArray[index].email = event.target.value
                              setFieldValue('alternativeEmail', updatedEmailArray);
                            }}
                          />
                        </Box>
                        <Box sx={webStyle.columnFieldControl}>
                          <label
                            style={webStyle.inputLabel}
                            htmlFor={"titleOfContactPerson"}
                          >
                            Use for
                          </label>
                          <Select
                            value={values.alternativeEmail[index].useFor}
                            data-test-id={`use-for-select-${index}`}
                            multiple
                            renderValue={(selected) => {
                              if (!selected.length) {
                                return <Box component="div" sx={webStyle.selectPlaceholder}>Select</Box>;
                              }

                              return (
                                <Box sx={webStyle.chipsWrapper}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} onDelete={(event) => {
                                      event.stopPropagation();
                                      const updatedEmailArray = [...values.alternativeEmail];
                                      updatedEmailArray[index].useFor = updatedEmailArray[index].useFor.filter((item) => item !== value);
                                      setFieldValue('alternativeEmail', updatedEmailArray);
                                    }} onMouseDown={(event) => event.stopPropagation()} sx={webStyle.chip} deleteIcon={<CloseRoundedIcon />} />
                                  ))}
                                </Box>
                              );
                            }}    
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            sx={webStyle.selectWithCheckboxes}
                            onChange={(event) => {
                              console.log(event.target.value, values.alternativeEmail);
                              const updatedEmailArray = [...values.alternativeEmail];
                              updatedEmailArray[index].useFor = [...event.target.value];
                              console.log(updatedEmailArray);
                              setFieldValue('alternativeEmail', updatedEmailArray);
                            }}
                            MenuProps={MenuProps}
                          >
                            <MenuItem
                              value={"Reports"}
                              sx={webStyle.selectOption}
                            >
                              <Checkbox sx={webStyle.checkbox} checked={!!values.alternativeEmail[index].useFor.find((item) => item === 'Reports')} />
                              <ListItemText primary={"Reports"} sx={webStyle.checkboxLabel} />
                            </MenuItem>
                            <MenuItem
                              value={"Invoices"}
                              sx={webStyle.selectOption}
                            >
                              <Checkbox sx={webStyle.checkbox} checked={!!values.alternativeEmail[index].useFor.find((item) => item === 'Invoices')} />
                              <ListItemText primary={"Invoices"} sx={webStyle.checkboxLabel} />
                            </MenuItem>
                          </Select>
                        </Box>
                        <Box
                          sx={webStyle.removeAlternativeEmail}
                          onClick={() => arrayHelpers.remove(index)}
                          data-test-id="removeEmail"
                        >
                          <img
                            src={removeIcon}
                            alt=""
                            style={webStyle.btnIcon}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Box sx={webStyle.addNewEmailWrapper}>
                      <button
                        style={webStyle.addNewEmailButton}
                        type="button"
                        onClick={() => {
                          arrayHelpers.push({
                            email: "",
                            useFor: [],
                          });
                        }}
                      >
                        <img src={addIcon} alt="" style={webStyle.btnIcon} />
                        <Typography style={webStyle.typographyTitleText}>
                          Add alternative email
                        </Typography>
                      </button>
                    </Box>
                  </>
                )}
              </FieldArray>
              <Box sx={webStyle.fieldGroupContral}>
                <Box sx={webStyle.columnFieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"contactPerson"}>
                    Contact person
                  </label>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="contactPerson"
                    type="text"
                    value={values?.contactPerson}
                    onChange={(event) => {
                      setFieldValue("contactPerson", event.target.value);
                    }}
                  />
                </Box>
                <Box sx={webStyle.columnFieldControl}>
                  <label
                    style={webStyle.inputLabel}
                    htmlFor={"titleOfContactPerson"}
                  >
                    Title of contact person
                  </label>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="titleOfContactPerson"
                    type="text"
                    value={values?.titleOfContactPerson}
                    onChange={(event) => {
                      setFieldValue("titleOfContactPerson", event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                <button
                  style={webStyle.prevButton}
                  data-test-id="cancelBtn"
                  type="button"
                  onClick={() => {}}
                >
                  <Typography sx={webStyle.buttonText}>Cancel</Typography>
                </button>
                <button
                  style={webStyle.submitButton}
                  data-test-id="submitBtn"
                  type="button"
                  onClick={() => {
                    this.setValidate(true);
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                >
                  <Typography sx={webStyle.buttonText}>Save</Typography>
                </button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  updateAccountDetailsForm = () => (
    <Formik
      initialValues={{
        zipCode: "",
        name: "",
        password: "",
        passwordConfirmation: "",
      }}
      validateOnMount={true}
      validateOnChange={true}
      validationSchema={this.questionSchema}
      onSubmit={() => {
        this.setValidate(true);
      }}
      data-test-id="updateAccountDetails"
    >
      {({ errors, setFieldValue, handleSubmit }) => (
        <form>
          <Box sx={webStyle.formContainer}>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"zipCode"}>
                {configJSON.zipCodeText}
              </label>
              <input
                style={webStyle.inputElemnt}
                data-test-id="zipCode"
                type="text"
                placeholder={configJSON.zipCodePlaceholderText}
                onChange={(event) => {
                  setFieldValue("zipCode", event.target.value);
                }}
              />
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"name"}>
                {configJSON.nameText}
              </label>
              <input
                style={webStyle.inputElemnt}
                data-test-id="name"
                type="text"
                placeholder={configJSON.namePlaceholderText}
                onChange={(event) => {
                  setFieldValue("name", event.target.value);
                }}
              />
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label style={webStyle.inputLabel} htmlFor={"password"}>
                {configJSON.passwordText}
              </label>
              <input
                style={{...webStyle.inputElemnt, ...webStyle.inputElementPassword}}
                data-test-id="password"
                type={this.state.showPassword ? "text" : "password"}
                placeholder={configJSON.passwordPlaceholderText}
                onChange={(event) => {
                  setFieldValue("password", event.target.value);
                }}
              />
              <IconButton
                style={webStyle.passwordHiddenIcon}
                onClick={() =>
                  this.setShowPassword(!this.state.showPassword)
                }
              >
                {this.state.showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>

              <Typography style={webStyle.passwordHelp}>
                {configJSON.alLeastSevenLlettersText}
              </Typography>
            </Box>
            <Box sx={webStyle.fieldControl}>
              <label
                style={webStyle.inputLabel}
                htmlFor={"passwordConfirmation"}
              >
                {configJSON.confirmPasswordText}
              </label>
              <input
                style={{...webStyle.inputElemnt, ...webStyle.inputElementPassword}}
                data-test-id="passwordConfirmation"
                type={this.state.showConfirmPassword ? "text" : "password"}
                placeholder={configJSON.confirmPasswordPlaceholderText}
                onChange={(event) => {
                  setFieldValue("passwordConfirmation", event.target.value);
                }}
              />
              <IconButton
                style={webStyle.passwordHiddenIcon}
                onClick={() =>
                  this.setShowConfirmPassword(!this.state.showConfirmPassword)
                }
              >
                {this.state.showConfirmPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </Box>
            <Box sx={webStyle.fieldControlFirstStepGroup}>
              <button
                style={webStyle.prevButton}
                data-test-id="cancelBtn"
                type="button"
                onClick={() => {}}
              >
                <Typography sx={webStyle.buttonText}>Cancel</Typography>
              </button>
              <button
                style={webStyle.submitButton}
                data-test-id="submitBtn"
                type="button"
                onClick={() => {
                  this.setValidate(true);
                  if (Object.keys(errors).length === 0) {
                    handleSubmit();
                  }
                }}
              >
                <Typography sx={webStyle.buttonText}>Save</Typography>
              </button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box sx={webStyle.signUpFormContainer}>
            <Box sx={webStyle.addReadingTitle}>
              <Typography style={webStyle.formTitle}>Settings</Typography>
            </Box>
            <Tabs value={this.state.step} onChange={(_, newValue) => this.setStep(newValue)} sx={webStyle.tabsContainer}>
              <Tab 
                value={1}
                label="Company details"
                data-test-id="firstStep"
                sx={webStyle.tabButton(this.state.step === 1)}
              />
              <Tab 
                value={2}
                label="Account settings"
                data-test-id="secondStep"
                sx={webStyle.tabButton(this.state.step === 2)}
              />
              <Tab 
                value={3}
                label="Notifications"
                data-test-id="thirdStep"
                sx={webStyle.tabButton(this.state.step === 3)}
              />
            </Tabs>
            <Box role="tabpanel" hidden={this.state.step !== 1}>
              {this.companyDetailsForm()}
            </Box>
            <Box role="tabpanel" hidden={this.state.step !== 2}>
              {this.updateAccountDetailsForm()}
            </Box>
            <Box role="tabpanel" hidden={this.state.step !== 3}></Box>
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: '100%',
    fontFamily: "'Inter', sans-serif",
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  signUpFormContainer: {
    margin: "40px auto",
    maxWidth: "600px",
    width: "80%",
  },
  addReadingTitle: {
    margin: "0 10px 20px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  fieldGroupContral: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  prevTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  prevEmptyTitle: {
    display: "flex",
    flexDirection: "row",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldControlSecontStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  aditionInputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
  },
  inputDisabledElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#F1F5F9",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaErrorElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    fontFamily: "'Inter', sans-serif",
  },
  textAreaComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  textAreaErrorComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#F87171",
    marginTop: "5px",
    marginBottom: "5px",
  },
  select: {
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontSize: '16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    color: '#0F172A',
    '& .MuiSelect-select': {
      fontFamily: "'Inter', sans-serif",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CBD5E1',
    }
  },
  selectWithCheckboxes: {
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontSize: '16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    color: '#0F172A',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CBD5E1',
    }
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: '#0F172A',
    padding: '8px 12px',
    margin: '0 8px 12px',
    borderRadius: '8px',
    '&:last-of-type': {
      margin: '0 8px',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    }
  },
  selectPlaceholder: {
    color: '#94A3B8',
  },
  prevButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 10px 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  informationBlock: {
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  informationFieldBlock: {
    margin: "10px 5px",
  },
  informationTitle: {
    color: "#94A3B8",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    alignItems: "center",
    display: "flex",
    margin: "0px 5px",
  },
  informationField: {
    color: "#0F172A",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  informationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
  tabsContainer: {
    borderBottom: '1px solid #e0e0e0',
    margin: '0 10px 30px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    }
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
  }),
  activeTabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row",
  },
  tabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    flexDirection: "row",
  },
  tabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  activeTabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  zipCodeFieldControl: {
    width: "135px",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  addressFieldControl: {
    flexBasis: "60%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  columnFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  addNewEmailWrapper: {
    margin: '6px 10px',
  },
  addNewEmailButton: {
    cursor: "pointer",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "0",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    width: '100%'
  },
  btnIcon: {
    width: "24px",
    height: "24px",
    margin: "4px",
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  passwordHelp: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "8px",
  },
  inputElementPassword: {
    padding: '10px 52px 10px 10px',
  },
  removeAlternativeEmail: {
    backgroundColor: "#FEF2F2",
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    position: "absolute",
    right: "-40px",
    bottom: "24px",
    cursor: "pointer",
  },
  checkboxLabel: {
    '& .MuiTypography-root': {
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
    }
  },
  checkbox: {
    width: '0px',
    height: '0px',
    borderRadius: '0px',
    color: '#64748B',
    marginRight: '8px',
    '& .MuiSvgIcon-root': {
      fontSize: '26px',
    },
    '&.Mui-checked': {
      color: '#1A7BA4',
    }
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px'
  },
  chip: {
    height: '22px',
    backgroundColor: '#F1F5F9',
    color: '#475569',
    fontWeight: '700',
    textTransform: 'uppercase',
    '& .MuiChip-label': {
      fontSize: '12px',
      padding: '0 2px 0 8px',
      marginTop: '3px',
    },
    '& .MuiChip-deleteIcon': {
      fontSize: '16px',
      margin: '0 8px 0 0',
      color: '#64748B',
    }
  }
};
// Customizable Area End
